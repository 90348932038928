<template>
  <div>
    <v-row>
      <v-col cols="4">
        <account-select
          v-model="account"
          :allowed-only="true"
          @input="setAccount()"
          label="Selecionar caixa"
        />
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <template v-if="account">
          <v-menu v-if="$acl.can('accountTransactionCreateEdit')" bottom left>
            <template v-slot:activator="{ on }">
              <v-btn class="text-capitalize" bottom color="primary" v-on="on">
                Novo
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="handleOpenStoreAccountTransactionForm('input')"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon color="success">mdi-arrow-top-right</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Nova Entrada</v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="handleOpenStoreAccountTransactionForm('output')"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon color="error">mdi-arrow-bottom-left</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Nova Saída</v-list-item-content>
              </v-list-item>
              <v-list-item @click="handleOpenTransferAccountTransactionForm()">
                <v-list-item-icon class="mr-2">
                  <v-icon color="primary">mdi-swap-horizontal</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Nova Transferência</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            @click="handleOpenSelectSalesReceivable()"
            color="primary"
            outlined
            class="text-capitalize mx-2"
          >
            <v-icon left>mdi-cash-register</v-icon>
            Vendas
          </v-btn>

          <v-btn
            v-if="$acl.can('billReceivableIndex')"
            @click="handleOpenPayBillReceivableDialog()"
            color="primary"
            outlined
          >
            <v-icon left>mdi-cash</v-icon>
            Crediário
          </v-btn>
        </template>

        <!-- <v-btn
          class="text-capitalize"
          v-if="$acl.can('manageAccountsIndex')"
          outlined
          color="primary"
          @click="$router.push('/gerenciar-contas')"
        >
          Gerenciar Contas
        </v-btn> -->
      </v-col>
    </v-row>
    <PendingTransactionAlert
      ref="PendingTransactionAlert"
      @viewReleases="openSelectPendingTransactions()"
      v-if="account"
      :account_id="account.id"
    />

    <v-row v-if="account">
      <v-col class="d-flex align-center" cols="4">
        <app-text-search-field
          @filter="showFilter = !showFilter"
          v-model="searchParams.description"
        />
        <v-btn
          @click="select()"
          class="text-capitalize ml-5"
          color="primary"
          large
        >
          <app-icon>search</app-icon>
        </v-btn>
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn
          color="primary"
          outlined
          :to="'/contas/importar-extrato?account_id='+account.id"
          class="mx-2"
          v-if="account.type == 'bank'"
        >
          <v-icon left>mdi-receipt-text</v-icon>
          Importar Extrato
        </v-btn>

        <v-btn @click="printReport()" color="primary" outlined>
          <v-icon left>mdi-printer</v-icon>
          Resumo
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="showFilter">
      <v-row class="d-flex align-center">
        <v-col>
          <AccountPlanSearch
            v-model="searchParams.accountPlan"
            @input="setAccountPlan($event)"
          />
        </v-col>

        <v-col>
          <app-text-field
            v-model="searchParams.start_date"
            label="Data Inicial"
            type="date"
          />
        </v-col>
        <v-col>
          <app-text-field
            v-model="searchParams.end_date"
            label="Data Final"
            type="date"
          />
        </v-col>
        <v-col cols="4" class="d-flex justify-center">
          <v-chip-group v-model="searchParams.status" column multiple>
            <v-chip small filter :value="'paid'" color="primary">
              Confirmados
            </v-chip>
            <v-chip small filter :value="'canceled'">Cancelados</v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn class="text-capitalize" @click="clearFilter()" outlined text>
            Limpar Filtro
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-row v-if="account">
      <v-col cols="9">
        <v-data-table
          :headers="headers"
          :items="accountTransactions.data"
          :items-per-page="50"
          hide-default-footer
          @click:row="handleOpenAccountTransactionDialog($event.id)"
        >
          <template v-slot:[`item.description`]="{ item }">
            <account-transaction-label :account-transaction="item" />
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>

          <template v-slot:[`item.date`]="{ item }">
            {{ $format.dateBr(item.date) }}
          </template>
          <template v-slot:[`item.payment_method`]="{ item }">
            <app-payment-method-chip :value="item.payment_method" />
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <app-money-label
              :value="item.value"
              :canceled="item.status == 'canceled'"
            />
          </template>
          <template v-slot:[`item.balance`]="{ item }">
            <app-money-label
              :value="item.balance"
              :canceled="item.status == 'canceled'"
            />
            <!-- <span v-if="item.status !== 'canceled'">{{ $format.decimal(item.balance) }}</span> -->
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="searchParams.page"
            :length="accountTransactions.last_page"
            @input="select($event)"
            :total-visible="9"
          ></v-pagination>
        </div>
      </v-col>
      <v-col class="pl-10" cols="3">
        <ReceiveSaleCard :account_id="account.id" />
        <AccountTransactionTotals
          class="mt-4"
          :value="accountTransactionsTotals"
        />
      </v-col>
    </v-row>
    <store-account-transaction-form
      ref="StoreAccountTransactionForm"
      @store="select()"
    />
    <transfer-account-transaction-form
      ref="TransferAccountTransactionForm"
      @store="select()"
    />
    <AccountTransactionDialog
      ref="AccountTransactionDialog"
      @store="select(searchParams.page)"
    />
    <SelectPendingTransactions
      ref="SelectPendingTransactions"
      @store="select(searchParams.page)"
    />
    <SelectSalesReceivable ref="SelectSalesReceivable" @store="select()" />
    <PayBillReceivableDialog ref="PayBillReceivableDialog" @store="select()" />
  </div>
</template>

<script>
import AccountTransactionTotals from "@/components/account/sections/AccountTransactionTotals.vue";
import ReceiveSaleCard from "@/components/account/sections/ReceiveSaleCard.vue";
import AccountTransactionLabel from "@/components/account/sections/AccountTransactionLabel.vue";
import AccountTransactionDialog from "@/components/account/sections/AccountTransactionDialog";
import AccountSelect from "@/components/account/ui/AccountSelect.vue";
import StoreAccountTransactionForm from "@/components/account/form/StoreAccountTransactionForm.vue";
import TransferAccountTransactionForm from "@/components/account/form/TransferAccountTransactionForm.vue";
import paymentMethods from "@/enums/paymentMethods";
import SelectPendingTransactions from "@/components/account/sections/SelectPendingTransactions.vue";
import PendingTransactionAlert from "@/components/account/sections/PendingTransactionAlert.vue";
import AccountPlanSearch from "@/components/account-plan/ui/AccountPlanSearch.vue";
import SelectSalesReceivable from "@/components/sales/sections/SelectSalesReceivable.vue";
import PayBillReceivableDialog from "@/components/account/sections/PayBillReceivableDialog.vue";
export default {
  components: {
    AccountSelect,
    AccountTransactionLabel,
    AccountTransactionDialog,
    AccountTransactionTotals,
    StoreAccountTransactionForm,
    TransferAccountTransactionForm,
    SelectPendingTransactions,
    PendingTransactionAlert,
    AccountPlanSearch,
    SelectSalesReceivable,
    ReceiveSaleCard,
    PayBillReceivableDialog,
  },

  data() {
    return {
      paymentMethods: paymentMethods,
      searchParams: {
        page: 1,
        status: ["paid", "canceled"],
      },
      showFilter: false,
      account: null,
      singleSelect: false,
      headers: [
        { text: "Descrição", value: "description", sortable: false },
        {
          text: "Data",
          value: "date",
          sortable: false,
          align: "center",
          width: "12%",
        },
        {
          text: "Meio Pg",
          value: "payment_method",
          sortable: false,
          align: "center",
          width: "12%",
        },
        {
          text: "Valor",
          value: "value",
          sortable: false,
          align: "end",
          width: "12%",
        },
        {
          text: "Saldo",
          value: "balance",
          sortable: false,
          align: "end",
          width: "12%",
        },
      ],
      accountTransactions: {},
      accountTransactionsTotals: null,
    };
  },

  computed: {
    user() {
      return this.$store.state.auth.userData.employee;
    },
  },

  created() {
    this.$store.dispatch("accounts/index");
  },

  methods: {
    handleSearchInput(text) {},

    async select(page = 1) {
      this.searchParams.page = page;
      this.$store.commit("app/showLoading");
      await this.$http
        .index("account/account-transaction", this.searchParams)
        .then((response) => {
          this.$refs.PendingTransactionAlert.select();
          this.accountTransactions = response.accountTransactions;
          this.accountTransactionsTotals = response.accountTransactionsTotals;
          this.$store.commit("app/hideLoading");
        });
    },

    handleOpenStoreAccountTransactionForm(type) {
      this.$refs.StoreAccountTransactionForm.open(type, this.account);
    },

    handleOpenTransferAccountTransactionForm() {
      this.$refs.TransferAccountTransactionForm.open(this.account);
    },

    handleOpenAccountTransactionDialog(id) {
      this.$refs.AccountTransactionDialog.open(id);
    },
    openSelectPendingTransactions() {
      this.$refs.SelectPendingTransactions.open(this.account.id);
    },
    setAccount() {
      this.searchParams.account_id = this.account.id;
      this.select();
    },
    setAccountPlan(accountPlan) {
      this.searchParams.account_plan_id = accountPlan.id;
    },
    clearFilter() {
      this.searchParams.accountPlan = null;
      this.searchParams.account_plan_id = null;
      this.searchParams.start_date = null;
      this.searchParams.end_date = null;
      this.searchParams.description = null;
      this.select();
    },
    handleOpenSelectSalesReceivable() {
      this.$refs.SelectSalesReceivable.open(this.account.id);
    },
    printReport() {
      this.$print.customPreview(`contas/relatorio/${this.account.id}`);
    },
    handleOpenPayBillReceivableDialog() {
      this.$refs.PayBillReceivableDialog.open();
    },
  },
};
</script>

<style></style>
